import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, FormInput } from "../../components";
import { useDispatch } from "react-redux";
import { update_password } from "../../features/usersSlice";
import { toast } from "react-toastify";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const ChangePasswordModal = ({ onClose, editingRecord }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: Yup.object().shape({
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: async (values) => {
      const c = window.confirm(
        "Are you sure you want to change your password?"
      );
      if (!c) return;
      try {
        const res = await dispatch(
          update_password({
            id: editingRecord?._id,
            password: values.password,
          })
        );
        if (res?.payload?.status === 200) {
          toast.success("Password updated successfully");
          onClose();
        }
      } catch (error) {
        console.error("Error updating password:", error);
        toast.error("Failed to update password");
      }
    },
  });

  return (
    <div className="fixed inset-0 flex items-start ml-[220px] justify-center z-40 bg-black/60">
      <div className="bg-white p-8 rounded-lg shadow-lg relative min-w-[500px] max-w-[90%]">
        <h2 className="text-lg font-semibold mb-4">
          Are you want to change the password?
        </h2>
        <div className="flex items-center gap-x-2 w-full">
          <FormInput
            type={showPassword ? "text" : "password"}
            name="password"
            label="New Password"
            placeholder="Enter new password"
            formik={formik}
            rootClass={"!w-full"}
          />
          <div className="flex items-center">
            {showPassword ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword(false)}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword(true)}
              />
            )}
          </div>
        </div>
        <div className="flex justify-end w-full gap-x-2">
          <Button
            text="Close"
            className="btn mt-1"
            color="#fff"
            onClick={onClose}
            variant="btn_cancel"
          />
          <Button
            text="Save"
            className="btn mt-1"
            color="#fff"
            onClick={formik.handleSubmit}
            variant="btn_submit"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordModal;
