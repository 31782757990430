import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSMSTemplate,
  deleteSMSTemplate,
  getSMSTemplates,
  updateSMSTemplate,
} from "../services/smsTemplateService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get Email Template
export const get_sms_template = createAsyncThunk(
  "get_template",
  async (data, thunkAPI) => {
    try {
      return await getSMSTemplates(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Create New SMS Template
export const create_sms_template = createAsyncThunk(
  "create_template",
  async (data, thunkAPI) => {
    try {
      return await createSMSTemplate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Update SMS Template
export const update_sms_template = createAsyncThunk(
  "update_template",
  async (data, thunkAPI) => {
    try {
      return await updateSMSTemplate(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// Delete SMS Template
export const delete_sms_template = createAsyncThunk(
  "delete_template",
  async (id, thunkAPI) => {
    try {
      return await deleteSMSTemplate(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const smsTemplateSlice = createSlice({
  name: "smsTemplate",
  initialState,
  reducers: {
    reset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_sms_template.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_sms_template.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_sms_template.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.record = [];
      })
      .addCase(create_sms_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_sms_template.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record.records.push(action.payload.data);
        state.record.totalItems = state.record.totalItems + 1;
      })
      .addCase(create_sms_template.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(update_sms_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(update_sms_template.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.records.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record.records[result] = {
          ...state.record.records[result],
          ...action.payload.data,
        };
      })
      .addCase(update_sms_template.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(delete_sms_template.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(delete_sms_template.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.records.filter(
          ({ _id }) => _id !== action?.payload?.data?._id
        );
        state.record.records = result;
      })
      .addCase(delete_sms_template.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { reset } = smsTemplateSlice.actions;
export default smsTemplateSlice.reducer;
