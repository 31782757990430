import React, { useEffect, useState } from "react";
import AddUpdateForm from "./AddUpdateForm";
import { FaEye, FaEyeSlash, FaSearch } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import {
  delete_user,
  get_users,
  get_password,
} from "../../features/usersSlice";
import { get_countries, get_states } from "../../features/countriesSlice";
import { get_roles } from "../../features/rolesSlice";
import { toast } from "react-toastify";
import PageHeader from "../../components/molecules/PageHeader";
import dayjs from "dayjs";
import errorMessage from "../../util/errorMessage";
import MUIDataTable from "../../components/molecules/DataTable/muigrid";
import { useLocation, useNavigate } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Button, DotsLoader } from "../../components";
import api from "../../services/api";
import { get_inbound_group_drd } from "../../features/inboundGroupSlice";
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import { Button as MUIButton } from "@mui/material";
import ChangePasswordModal from "./ChangePasswordModal";
const User = () => {
  const location = useLocation();
  const optionsRef = React.useRef();
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const { isLoading, users } = useSelector((state) => state.users);
  const roles = useSelector((state) => state.roles.record);
  const navigate = useNavigate();
  const [editingRecord, setEditingRecord] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isPassword, setIsPassword] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [showPassword, setShowPassword] = useState("");
  const [showMenu, setShowMenu] = useState(null);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 100,
    page: 1,
  });
  const [queryOptions, setQueryOptions] = React.useState({
    groupOp: "AND",
    rules: [],
  });
  const [sortingModel, setSortingModel] = React.useState({
    sort_field: null,
    sort_by: null,
  });
  const dispatch = useDispatch();
  const dialerRoles = [
    { label: "Agent", value: 1 },
    { label: "Admin", value: 8 },
  ];
  const dialerOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];
  const activeOptions = [
    { label: "Active", value: 1 },
    { label: "Inactive", value: 0 },
  ];

  const cancelFormHandler = () => {
    setEditingRecord(null);
    setIsEditing(false);
    setIsPassword(false);
  };
  const handleHideDropdown = (event) => {
    if (showMenu && event.key === "Escape") {
      setShowMenu(null);
    }
  };
  const handleClickOutside = (event) => {
    if (
      showMenu &&
      optionsRef.current &&
      !optionsRef.current.contains(event.target)
    ) {
      setShowMenu(null);
    }
  };
  document.addEventListener("keydown", handleHideDropdown, true);
  document.addEventListener("click", handleClickOutside, true);
  const deleteRecordHandler = async (record_id) => {
    const c = window.confirm("Are you sure to perform this action?");
    if (c) {
      try {
        const res = await dispatch(delete_user(record_id));
        if (res?.payload?.status === 200) {
          toast.success("User deleted");
          cancelFormHandler();
        } else {
          errorMessage({
            payload: res.payload,
            action: "User",
            msg: "deleted",
          });
        }
      } catch (error) {
        errorMessage({ payload: error, action: "User", msg: "deleted" });
      }
    }
  };
  const openFormHandler = (record) => {
    setEditingRecord(record);
    setIsEditing(true);
  };
  const openPasswordHandler = (record) => {
    setEditingRecord(record);
    setIsPassword(true);
  };
  useEffect(() => {
    dispatch(
      get_users({
        filters: queryOptions,
        ...sortingModel,
        page: 1,
        size: paginationModel.pageSize,
      })
    );
    dispatch(get_roles());
    dispatch(get_countries());
    dispatch(get_states());
    // dispatch(get_industry_drd());
    dispatch(get_inbound_group_drd());
    // eslint-disable-next-line
  }, []);
  const sendVerificationEmail = async (mail) => {
    const c = window.confirm(
      "Are you sure want to send the verification email?"
    );
    if (!c) return;
    setIsLoader(true);
    try {
      const res = await api.post("/api/users/send-verification-email", {
        email: mail,
      });
      if (res.status === 200) {
        toast.success(
          res.data?.message || "Verification email send successfully"
        );
      } else {
        toast.error(res.data?.message || "Verification email couldn't be send");
      }
      setIsLoader(false);
    } catch (err) {
      setIsLoader(false);
      toast.error(
        err?.response?.data?.message || "Verification email couldn't be send"
      );
    }
  };
  const filterUsers = users?.users?.filter((user) => {
    const searchTextLower = searchText.toLowerCase().trim();
    const userFirstNameLower = user?.first_name?.toLowerCase() || "";
    const userLastNameLower = user?.last_name?.toLowerCase() || "";
    const userEmailLower = user?.email?.toLowerCase() || "";
    const userNameLower = user?.username?.toLowerCase() || "";
    const userRoleNameLower = user?.role_id?.name?.toLowerCase() || "";
    const userPrimaryMerchantNameLower =
      user?.primary_merchant_id?.name?.toLowerCase() || "";
    const userFullNameLower = `${userFirstNameLower} ${userLastNameLower}`;
    const lastLoginIp = user?.last_login_ip?.toLowerCase() || "";
    return (
      userFullNameLower.includes(searchTextLower) ||
      userEmailLower.includes(searchTextLower) ||
      userNameLower.includes(searchTextLower) ||
      userRoleNameLower.includes(searchTextLower) ||
      lastLoginIp.includes(searchTextLower) ||
      userPrimaryMerchantNameLower.includes(searchTextLower)
    );
  });
  const openDistributionSettingForm = (record) => {
    navigate("/users/lead_distribution", { state: record });
  };
  const handleGetpassword = async (record, password) => {
    if (password) {
      return setShowPassword(record);
    }
    setIsLoader(true);
    try {
      const res = await api.get(`/api/users/password/${record}`);
      if (res?.status === 200) {
        setShowPassword(record);
        const newData = [...users?.users];
        const resultIndex = newData.findIndex(({ _id }) => _id === record);
        if (resultIndex > -1) {
          newData[resultIndex] = {
            ...newData[resultIndex],
            pswrd: res?.data.password,
          };
          dispatch(
            get_password({ users: newData, totalItems: users?.totalItems })
          );
        }
      }
    } catch (err) {
      console.log("👊 ~ searchVerifiedJob ~ err:", err);
    }
    setIsLoader(false);
  };
  const testMenu = [
    { label: "Edit user", action: (e) => openFormHandler(e) },
    {
      label: "Delete User",
      action: (e) => deleteRecordHandler(e._id),
      isHide: user?.company_name === "Verified CRM",
    },
    { label: "Change Password", action: (e) => openPasswordHandler(e) },
    {
      label: "Lead distribution",
      action: (e) => openDistributionSettingForm(e._id),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Roles",
      action: () => toast.info("Coming soon"),
      isHide: user?.company_name === "Verified CRM",
    },
    {
      label: "Setting",
      action: () => toast.info("Coming soon"),
      isHide: user?.company_name === "Verified CRM",
    },
  ];
  const columnDefs = [
    { headerName: "#", field: "counter", width: 80 },
    { headerName: "Name", field: "name" },
    {
      headerName: "Email",
      field: "email",
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <div className="flex flex-col">
          <span>{params.row.email}</span>
          {params.row.email_verified ? (
            <small className="text-green-600">Verified</small>
          ) : (
            <small
              className="text-red-600 hover:underline cursor-pointer ml3"
              onClick={() => sendVerificationEmail(params.row.email)}
            >
              Not verified
            </small>
          )}
        </div>
      ),
    },
    { headerName: "User Name", field: "username" },
    {
      field: "pswrd",
      headerName: "Password",
      width: 130,
      renderCell: (params) => {
        return (
          <div className="">
            <span className="!mr-2">
              {showPassword === params.row.records?._id
                ? params.row.records.pswrd
                : "*******"}
            </span>
            {showPassword === params.row.records._id ? (
              <FaEye
                className="my_navIcon cursor-pointer"
                onClick={() => setShowPassword("")}
              />
            ) : (
              <FaEyeSlash
                className="my_navIcon cursor-pointer"
                onClick={() =>
                  handleGetpassword(
                    params.row.records._id,
                    params.row.records.pswrd
                  )
                }
              />
            )}
          </div>
        );
      },
    },
    {
      headerName: "Role",
      field: "role_id",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?._id,
      getOptionLabel: (option) => option.name,
      valueOptions: roles,
      renderCell: (params) => {
        const list = roles.find((list) => list.name === params?.row?.role);
        return list ? list.name : "N/A";
      },
    },
    {
      headerName: "Dialer User",
      field: "is_dialer_user",
      width: 100,
      type: "singleSelect",
      getOptionValue: (option) => option?.value,
      getOptionLabel: (option) => option.label,
      valueOptions: dialerOptions,
      renderCell: (params) => {
        const item = dialerOptions.find(
          (item) => item.label === params?.row?.is_dialer_user
        );
        return item ? item.label : "N/A";
      },
    },
    {
      headerName: "Dialer Info",
      field: "dialer_user",
      filterable: false,
      width: 100,
      renderCell: (params) => {
        return params?.row?.is_dialer_user === "Yes" ||
          params?.row?.dialer_user_info?.dialer_user ? (
          <div className="flex flex-col">
            <span>User: {params.row.dialer_user_info?.dialer_user || ""}</span>
            <span>
              Phone: {params.row.dialer_user_info?.dialer_phone || ""}
            </span>
            <span>
              Role:{" "}
              {dialerRoles?.find(
                ({ value }) =>
                  value === params.row.dialer_user_info?.dialer_role
              )?.label || ""}
            </span>
          </div>
        ) : (
          "N/A"
        );
      },
    },
    {
      headerName: "Created At",
      field: "createdAt",
      width: 120,
      sortable: true,
      valueGetter: (params) => params.row.createdAt,
      sortComparator: (v1, v2, row1, row2) => {
        const date1 = new Date(row1.value);
        const date2 = new Date(row2.value);
        return date1 - date2;
      },
    },
    { headerName: "Status", field: "status", width: 80 },
    {
      headerName: "Action",
      field: "actions",
      align: "center",
      renderCell: (params) => (
        <div>
          <BsThreeDotsVertical
            size={18}
            className="cursor-pointer !relative hover:text-primary-100"
            onClick={() => setShowMenu(params.row.records._id)}
          />
          {showMenu === params.row.record_id ? (
            <div
              ref={optionsRef}
              className="absolute w-auto right-12 min-w-[100px] rounded shadow !mt-1 bg-white z-20 border-x border-y border-[#ddd] -ml-px overflow-hidden transition ease-in-out delay-150"
            >
              <ul className="!pl-0 !mb-0">
                {testMenu?.map((option, index) => {
                  return !option.isHide ? (
                    <li
                      key={index}
                      className="cursor-pointer px-3 !py-1.25 border- border-[#ddd] hover:bg-[#e1e1e1]"
                      onClick={() => option.action(params.row.records)}
                    >
                      {option.label}
                    </li>
                  ) : null;
                })}
              </ul>
            </div>
          ) : null}
        </div>
      ),

      width: 100,
    },
  ].filter(Boolean);
  const handleNewFilter = () => {
    setPaginationModel({ pageSize: paginationModel.pageSize, page: 1 });
    dispatch(
      get_users({
        page: 1,
        ...sortingModel,
        size: paginationModel.pageSize,
        filters: queryOptions,
      })
    );
  };
  function CustomToolbar({ setFilterButtonEl }) {
    return (
      <GridToolbarContainer className="!text-[#042a42]">
        <GridToolbarColumnsButton className="!text-[#042a42]" />
        <GridToolbarDensitySelector className="!text-[#042a42]" />
        <GridToolbarFilterButton
          ref={setFilterButtonEl}
          className="!text-[#042a42]"
        />
        <MUIButton
          variant="text"
          onClick={handleNewFilter}
          startIcon={<FaSearch size={16} />}
          sx={{ fontSize: "0.8125rem" }}
          className="!text-[#042a42]"
        >
          Apply filter
        </MUIButton>
      </GridToolbarContainer>
    );
  }
  const onFilterChange = React.useCallback((filterModel) => {
    let ruless = [];
    if (filterModel?.items?.length === 0) {
      dispatch(
        get_users({
          ...sortingModel,
          filters: queryOptions,
          page: 1,
          size: paginationModel.pageSize,
        })
      );
    }
    // eslint-disable-next-line array-callback-return
    filterModel.items?.map((rule) => {
      ruless = [
        ...ruless,
        {
          field: `${rule.field}`,
          op:
            rule.operator === "contains"
              ? "cn"
              : rule.operator === "equals"
              ? "eq"
              : rule.operator === "is"
              ? "eq"
              : rule.operator === "="
              ? "eq"
              : rule.operator === "!="
              ? "not"
              : rule.operator === ">"
              ? "gt"
              : rule.operator === ">="
              ? "gte"
              : rule.operator === "<"
              ? "lt"
              : rule.operator === "<="
              ? "lte"
              : rule.operator,
          data: rule.value
            ? rule.value
            : rule.value === 0
            ? rule.value
            : rule.value === false
            ? rule.value
            : null,
        },
      ];
    });
    setQueryOptions({
      groupOp: filterModel.logicOperator.toUpperCase(),
      rules: ruless,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel({ ...paginationModel, pageSize: newPageSize });
  };
  const handlePageChange = (params) => {
    setPaginationModel({ pageSize: params.pageSize, page: params.page + 1 });
    dispatch(
      get_users({
        ...sortingModel,
        filters: queryOptions,
        page: +params.page + 1,
        size: params.pageSize,
      })
    );
  };
  const CustomButtons = () => {
    return (
      <div className="flex gap-x-2">
        <Button
          text="Add New User"
          variant="btn_submit"
          onClick={() => openFormHandler(0)}
        />
        <Button
          text="Manage Groups"
          variant="btn_submit"
          onClick={() =>
            navigate(`${location?.pathname?.replace("users", "user_groups")}`)
          }
        />
        <Button
          text="Roles & Permissions"
          variant="btn_submit"
          onClick={() =>
            navigate(
              `${location?.pathname?.replace("users", "user_permissions")}`
            )
          }
        />
      </div>
    );
  };
  const handleSortModelChange = (params) => {
    setSortingModel({
      sort_field: params[0]?.field,
      sort_by: params[0]?.sort || "default",
    });
    dispatch(
      get_users({
        filters: queryOptions,
        page: 1,
        size: paginationModel.pageSize,
        sort_field: params[0]?.field,
        sort_by: params[0]?.sort || "default",
      })
    );
  };
  const offset = (paginationModel?.page - 1) * paginationModel?.pageSize;
  return (
    <>
      {isEditing && (
        <AddUpdateForm
          editingRecord={editingRecord}
          modalTitle={editingRecord ? "Update User" : "Add User"}
          onCancelForm={cancelFormHandler}
        />
      )}
      {isPassword ? (
        <ChangePasswordModal
          onClose={cancelFormHandler}
          editingRecord={editingRecord}
        />
      ) : null}
      {isLoader ? <DotsLoader /> : null}
      <PageHeader
        route="Setting > Users"
        heading=""
        CustomButtons={CustomButtons}
      />
      <div className="bg-white my-3 border rounded">
        <MUIDataTable
          columnDefs={columnDefs}
          items={filterUsers?.map((record, index) => {
            const { email_verified, receiving_leads, is_dialer_user } = record;
            let records = record;
            let counter = offset + index + 1;
            let record_id = record._id;
            let name =
              record?.first_name +
              " " +
              (record?.last_name ? record?.last_name : "");
            let email = record.email;
            let username = record?.username;
            let role = record?.role_id?.name;
            let role_level = record?.role_id?.level;
            let primaryMerchant = record?.primary_merchant_id?.name;
            let createdAt = record.createdAt
              ? dayjs(record?.createdAt).format("ddd, MMM D, YYYY h:mm A")
              : null;
            let lastLoginIp = record.last_login_ip;
            let lastLoginDate = record.last_login_date
              ? dayjs(record.last_login_date).format("ddd, MMM D, YYYY h:mm A")
              : "";
            let ipFiltering = record.ip_filtering ? "Yes" : "No";
            let status = record.active ? "Active" : "InActive";
            let pswrd = record.pswrd;
            let dialer_user_info = record?.dialer_user_info || {};
            return {
              counter,
              name,
              email,
              username,
              role,
              primaryMerchant,
              createdAt,
              lastLoginIp,
              lastLoginDate,
              ipFiltering,
              status,
              records,
              record_id,
              pswrd,
              role_level,
              email_verified,
              receiving_leads,
              dialer_user_info,
              is_dialer_user: is_dialer_user ? "Yes" : "No",
            };
          })}
          onPaginationModelChange={handlePageChange}
          onPageSizeChange={handlePageSizeChange}
          onFilterModelChange={onFilterChange}
          totalItems={users.totalItems}
          searchText={searchText}
          setSearchText={setSearchText}
          paginationModel={paginationModel}
          isLoading={isLoading}
          sortingMode="server"
          density="standard"
          onSortModelChange={handleSortModelChange}
          CustomToolbar={CustomToolbar}
          // pagination="No"
          showCount="No"
          height="66vh"
          // exportButton={exportToCsv}
        />
      </div>
    </>
  );
};

export default User;
