export const filterPayload = (obj) => {
  let result = {};
  Object.keys(obj).forEach((key) => {
    if (
      obj[key] !== null &&
      obj[key] !== undefined &&
      obj[key] !== "" &&
      obj[key] !== "Invalid Date"
    ) {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const filteredNestedObject = filterPayload(obj[key]); // Recursively filter nested objects
        if (Object.keys(filteredNestedObject).length > 0) {
          result[key] = filteredNestedObject;
        }
      } else {
        result[key] = obj[key];
      }
    }
  });
  return result;
};

export const filterPayloadToNull = (obj) => {
  let result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const filteredNestedObject = filterPayloadNull(obj[key]); // Recursively filter nested objects
        if (Object.keys(filteredNestedObject).length > 0) {
          result[key] = filteredNestedObject;
        }
      } else {
        result[key] = obj[key];
      }
    }
  });
  return result;
};
export const filterPayloadNull = (obj) => {
  let result = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] === "" || obj[key] === undefined) {
      // Set empty strings and undefined values to null
      result[key] = null;
    } else if (obj[key] !== null) {
      // Recursively handle nested objects if value is a non-null object
      if (typeof obj[key] === "object" && !Array.isArray(obj[key])) {
        const filteredNestedObject = filterPayload(obj[key]);
        result[key] = filteredNestedObject;
      } else {
        result[key] = obj[key];
      }
    } else {
      // Keep keys that are explicitly set to null
      result[key] = obj[key];
    }
  });
  return result;
};
export const filterArrayValues = (obj) => {
  if (Array.isArray(obj)) {
    return obj
      .map((item) =>
        typeof item === "object" && item !== null
          ? filterArrayValues(item)
          : item
      )
      .filter(
        (item) =>
          item !== null &&
          item !== undefined &&
          item !== "" &&
          item !== "Invalid Date"
      );
  } else if (typeof obj === "object" && obj !== null) {
    let result = {};
    Object.keys(obj).forEach((key) => {
      if (obj[key] !== null && obj[key] !== undefined && obj[key] !== "") {
        if (typeof obj[key] === "object") {
          const filteredNestedObject = filterArrayValues(obj[key]);
          if (
            Object.keys(filteredNestedObject).length > 0 ||
            Array.isArray(filteredNestedObject)
          ) {
            result[key] = filteredNestedObject;
          }
        } else {
          result[key] = obj[key];
        }
      }
    });
    return result;
  }
  return obj;
};
// export {filterArrayValues, filterPayload}
