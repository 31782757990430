import React, { Suspense } from "react";
import {
  Routes,
  Route,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import { Layout, Loader } from "./components";
import { useSelector } from "react-redux";
import { Login } from "./views/";
import ForgotPassword from "./views/Login/forgetPassword";
import ConfirmPassword from "./views/Login/confirmPassword";
import { SocketProvider } from "./context/socketContext";
import {
  NotificationHandler,
  NotificationProvider,
} from "./components/LeadNotifications";
const AddUpdatePage = React.lazy(() =>
  import("./views/VerifiedLead/AddUpdatePage")
);
const AddUpdatePageNew = React.lazy(() =>
  import("./views/Leads/AddUpdatePage")
);
const LeadAddEditWrapper = () => {
  const location = useLocation();
  const key = location.pathname + location.search;

  return <AddUpdatePage key={key} />;
};
const LeadAddEditWrapperNew = () => {
  const location = useLocation();
  const key = location.pathname + location.search;

  return <AddUpdatePageNew key={key} />;
};
const Router = () => {
  const { user } = useSelector((state) => state.auth);
  const permissions = [
    ...(user?.menu_permissions || []),
    ...(user?.permissions || []),
  ];
  let routes;
  if (!user) {
    routes = [
      { path: "/login", element: <Login /> },
      { path: "/forgot_password", element: <ForgotPassword /> },
      { path: "/reset/:token", element: <ConfirmPassword /> },
      { path: "*", element: <Navigate to="/login" replace /> },
    ];
  } else {
    routes = [
      {
        path: "/dashboard",
        permission: `Dashboard`,
        component: React.lazy(() => import("./views/Dashboard")),
      },
      {
        path: "/settings/users",
        permission: `${user?.company_name !== "Verified CRM" ? "User" : "All"}`,
        component: React.lazy(() => import("./views/User")),
      },
      {
        path: "/settings/user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups")),
      },
      {
        path: "/settings/user_groups/assign_user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups/AssignUsers")),
      },

      {
        path: "/settings/administration/emailTemplates",
        permission: "Email & SMS",
        component: React.lazy(() => import("./views/EmailTemplate")),
      },

      {
        path: "/settings/roles",
        permission: "User Roles",
        component: React.lazy(() => import("./views/Roles")),
      },

      {
        path: "/callcenter/inbound_groups",
        permission: "Ring Groups",
        component: React.lazy(() => import("./views/CallCenter/InGroup")),
      },
      {
        path: "/callcenter/campaigns",
        permission: "Campaigns",
        component: React.lazy(() => import("./views/CallCenter/Campaigns")),
      },
      {
        path: "/callcenter/dids",
        permission: "DIDs",
        component: React.lazy(() => import("./views/CallCenter/DIDs")),
      },
      {
        path: "/callcenter/call_centers",
        permission: "Call Centers",
        component: React.lazy(() => import("./views/CallCenter/CallCenters")),
      },
      {
        path: "/callcenter/audio_files",
        permission: "Audio Files",
        component: React.lazy(() => import("./views/CallCenter/AudioUpload")),
      },
      {
        path: "/callcenter/call_menu",
        permission: "Call Menu",
        component: React.lazy(() => import("./views/CallCenter/CallMenu")),
      },
      {
        path: "/callcenter/call_menu/:id",
        permission: "Call Menu",
        component: React.lazy(() =>
          import("./views/CallCenter/CallMenu/UpdateCallMenu")
        ),
      },
      {
        path: "/callcenter/spam_numbers",
        permission: "Spam Numbers",
        component: React.lazy(() => import("./views/CallCenter/SpamNumbers")),
      },

      {
        path: "/realtime",
        permission: "Real Time",
        component: React.lazy(() => import("./views/RealTime")),
      },
      {
        path: "/pdftohtml",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead/PdfHtml")),
      },

      {
        path: "/settings/lead_source",
        permission: "Lead Source",
        component: React.lazy(() => import("./views/LeadSource")),
      },
      // {
      //   path: "/settings/lead_source/leadSource_AddEdit",
      //   permission: "Lead Source",
      //   component: React.lazy(() => import("./views/LeadSource/AddUpdatePage")),
      // },
      {
        path: "/settings/lead_source/leadSource_AddEdit/:id?",
        permission: "Lead Source",
        component: React.lazy(() =>
          import("./views/LeadSource/AddUpdatePageNew")
        ),
      },
      {
        path: "/settings/lead_source_group",
        permission: "Read Lead Source Group",
        component: React.lazy(() => import("./views/LeadSourceGroup")),
      },
      {
        path: "/settings/administration/lead_status",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus")),
      },
      {
        path: "/settings/administration/lead_status/assign_lead_actions",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus/AssignActions")),
      },
      {
        path: "/settings/administration/lead_status/assign_lead_email",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus/AssignEmail")),
      },
      {
        path: "/settings/administration/lead_status/assign_lead_sms",
        permission: "All",
        component: React.lazy(() => import("./views/LeadStatus/AssignSMS")),
      },
      {
        path: "/settings/administration/lead_actions",
        permission: "All",
        component: React.lazy(() => import("./views/LeadAction")),
      },
      {
        path: "/settings/administration/users",
        permission: "Users & Groups",
        component: React.lazy(() => import("./views/User")),
      },
      {
        path: "/settings/administration/system_settings",
        permission: "All",
        component: React.lazy(() => import("./views/SystemSettings")),
      },
      {
        path: "/settings/administration/roles",
        permission: "All",
        component: React.lazy(() => import("./views/Roles")),
      },
      {
        path: "/settings/administration/user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups")),
      },
      {
        path: "/settings/administration/user_groups/assign_user_groups",
        permission: "All",
        component: React.lazy(() => import("./views/UserGroups/AssignUsers")),
      },
      {
        path: "/commission",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Commission")),
      },
      {
        path: "/reports",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Reports")),
      },
      {
        path: "/reports/ppw_in_ira",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/Reports/PPWInIRA")
        ),
      },
      {
        path: "/settings/administration/email_sms",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/EmailSms")),
      },
      {
        path: "/settings/inventory",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Inventory")),
      },
      {
        path: "/settings/inbox",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Inbox")),
      },
      {
        path: "/settings/docusign_templates",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/DocusignTemplates")
        ),
      },
      {
        path: "/settings/inbox/detail",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/Inbox/InboxDetail")
        ),
      },
      {
        path: "/settings/administration/user_permissions",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedLead/UserPermissions")
        ),
      },
      {
        path: "/settings/administration/permissions",
        permission: "All",
        component: React.lazy(() => import("./views/Permissions")),
      },
      {
        path: "/verified_lead/lead_distribution",
        permission: "All",
        component: React.lazy(() =>
          import(
            "./views/VerifiedCrm/DistributionProgram/AddUpdateLeadDistribution"
          )
        ),
      },
      {
        path: "/users/lead_distribution",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedLead/SystemDistribution")
        ),
      },

      {
        path: "/settings/distribution_categories",
        permission: "All",
        component: React.lazy(() => import("./views/DistributionCategory")),
      },
      {
        path: "/products",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Products")),
      },
      {
        path: "/settings/distribution_program",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/DistributionProgram")
        ),
      },
      {
        path: "/settings/distribution_program/distribution_program_AddEdit",
        permission: "All",
        component: React.lazy(() =>
          import(
            "./views/VerifiedCrm/DistributionProgram/AddUpdateLeadDistribution"
          )
        ),
      },
      {
        path: "/verified_lead/:lead_type?/:lead_status?/:lead_sub_status?",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead")),
      },
      {
        path: "/verified_lead?searchValue=:searchValue",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedLead")),
      },
      {
        path: "/customers",
        permission: "All",
        component: React.lazy(() => import("./views/VerifiedCrm/Customers")),
      },
      {
        path: "/verified_lead/leads/lead_AddEdit?customer_id=:customer_id&lead_id=:lead_number",
        permission: "All",
        element: <LeadAddEditWrapper />,
      },
      {
        path: "/verified_lead/:lead_type?/:lead_status?/:lead_sub_status?/lead_AddEdit/:lead_id?",
        permission: "All",
        element: <LeadAddEditWrapper />,
      },
      {
        path: "/calendar_events",
        permission: "Calendar",
        component: React.lazy(() => import("./views/VerifiedCrm/Calendar")),
      },
      {
        path: "/dids_inventory",
        permission: "DIDs Inventory",
        component: React.lazy(() => import("./views/DIDInventory")),
      },
      {
        path: "/settings/workflow_status",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/WorkflowStatus")
        ),
        permission: "All",
      },
      {
        path: "/settings/inventory/inventory_AddEdit",
        permission: "All",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/Inventory/AddUpdatInventoryPage")
        ),
      },
      {
        path: "/settings/vendor_price",
        component: React.lazy(() => import("./views/VerifiedCrm/VendorPrice")),
        permission: "All",
      },
      {
        path: "/settings/commission_spread",
        component: React.lazy(() =>
          import("./views/VerifiedCrm/CommissionSpread")
        ),
        permission: "All",
      },
      {
        path: "/task_list",
        component: React.lazy(() => import("./views/TaskList")),
        permission: "All",
      },
      {
        path: "/settings/lead_source_type",
        component: React.lazy(() => import("./views/LeadSourceType")),
        permission: "All",
      },
      {
        path: "/settings/lead_source_providers",
        component: React.lazy(() => import("./views/LeadSourceProvider")),
        permission: "All",
      },
      {
        path: "/settings/api_users",
        component: React.lazy(() => import("./views/APIUser")),
        permission: "All",
      },
      {
        path: "/settings/restricted_words",
        component: React.lazy(() => import("./views/RestrictedWords")),
        permission: "All",
      },
      {
        path: "/settings/administration/smsTemplates",
        component: React.lazy(() => import("./views/SMSTemplate")),
        permission: "SMS Templates",
      },
      {
        path: "/priority_view/leads",
        component: React.lazy(() => import("./views/VerifiedCrm/PriorityView")),
        permission: "All",
      },
      {
        path: "/callcenter/reports/agent_performance_report",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/AgentPerformanceReport")
        ),
        permission: "All",
      },
      {
        path: "/callcenter/reports/call_logs",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/InBound")
        ),
        permission: "All",
      },
      {
        path: "/callcenter/reports/call_summary_report",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/CallSummary")
        ),
        permission: "All",
      },
      {
        path: "/callcenter/reports/inbound_forwarding_report",
        component: React.lazy(() =>
          import("./views/CallCenter/Reports/InBoundForwarding")
        ),
        permission: "All",
      },
      // New Leads Start
      {
        path: "/:lead_type/list",
        permission: "All",
        component: React.lazy(() => import("./views/Leads")),
      },
      {
        path: "/:lead_type?/lead_AddEdit/:lead_id?",
        permission: "All",
        element: <LeadAddEditWrapperNew />,
      },
      {
        path: "/leads/lead_AddEdit?customer_id=:customer_id&lead_id=:lead_number",
        permission: "All",
        element: <LeadAddEditWrapperNew />,
      },
      // New Leads End
    ];
  }

  return (
    <SocketProvider>
      <NotificationProvider>
        <NotificationHandler />
        <BrowserRouter>
          <Suspense
            fallback={
              <div className="flex flex-auto flex-col h-[100vh]">
                <Loader loading={true} />
              </div>
            }
          >
            <Layout>
              <Routes>
                {user ? (
                  <Route
                    path="*"
                    element={<Navigate to={"/leads/list"} replace />}
                  />
                ) : (
                  <Route path="*" element={<Navigate to="/login" replace />} />
                )}
                {routes.map((route, index) => {
                  const foundPermission = permissions?.includes(
                    route?.permission
                  );
                  const { component: Component, element } = route;
                  if (foundPermission || !user || route?.permission === "All") {
                    return (
                      <Route
                        key={index}
                        {...route}
                        element={Component ? <Component /> : element}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </Routes>
            </Layout>
          </Suspense>
        </BrowserRouter>
      </NotificationProvider>
    </SocketProvider>
  );
};

export default Router;
