import { FaArrowUp, FaPhoneAlt, FaSms } from "react-icons/fa";
import { BsFillRecordCircleFill } from "react-icons/bs";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { get_missedcall_count } from "../../../features/dialerSlice";
import userPermission from "../../../util/userPermission";
const Footer = (props) => {
  const dispatch = useDispatch();
  const { missedCallsCount } = useSelector((state) => state.dialer);
  let user = localStorage.getItem("user");
  user = user ? JSON.parse(user) : null;
  const location = useLocation();
  const job_type = location.pathname.startsWith("/jobs/update/preview");
  const isUpdating = location.pathname.startsWith("/jobs/update");
  const currentDate = new Date();
  const fromDate =
    new Date(
      props.misscallDate.getFullYear(),
      props.misscallDate.getMonth(),
      props.misscallDate.getDate(),
      0,
      0,
      0,
      0
    )
      .toISOString()
      .slice(0, 23) + "Z";
  const toDate =
    currentDate.toDateString() === props.misscallDate.toDateString()
      ? new Date().toISOString().slice(0, 23) + "Z"
      : new Date(
          props.misscallDate.getFullYear(),
          props.misscallDate.getMonth(),
          props.misscallDate.getDate(),
          23,
          59,
          59,
          0
        )
          .toISOString()
          .slice(0, 23) + "Z";
  const initialFilters = {
    from_date: new Date(fromDate).getTime() / 1000,
    to_date: new Date(toDate).getTime() / 1000,
  };
  React.useEffect(() => {
    if (userPermission("Jobs") && user?.company_name !== "Verified CRM") {
      dispatch(get_missedcall_count(initialFilters));
    }
    const timerID = setInterval(() => {
      if (userPermission("Jobs") && user?.company_name !== "Verified CRM") {
        dispatch(get_missedcall_count(initialFilters));
      }
    }, 600000);

    return () => {
      clearInterval(timerID);
    };
    // eslint-disable-next-line
  }, [props.misscallDate]);

  return (
    <>
      <footer className="z-10">
        <div className="flex justify-between items-center h-full">
          {user?.dialer_user_info?.dialer_user &&
          user?.dialer_login_url &&
          !job_type ? (
            <div
              className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-[82.2px] flex text-center leading-4 relative flex-col justify-center"
              onClick={() =>
                props.setIsDialer(props.isDialer === "flex" ? "hidden" : "flex")
              }
            >
              <div className="flex justify-center items-center flex-col">
                <FaPhoneAlt size={12} />
                <small>Dialer</small>
              </div>
            </div>
          ) : null}
          {userPermission("Chat") ? (
            <div className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-[82.2px] flex text-center leading-4 relative flex-col justify-center">
              <div className="flex justify-center items-center flex-col">
                <FaSms size={12} />
                <small>Chat</small>
              </div>
            </div>
          ) : null}
          {userPermission("SMS Center") ? (
            <div
              className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-2/5 flex relative flex-col justify-center"
              onClick={() => props.setIsSMS(!props.isSMS)}
            >
              <div className="flex justify-center flex-col w-full p-2">
                <small>Here is your Smart Chat</small>
                {/* {unreadCount > 0 && (
                  <span className="left-[140px] absolute top-4 translate-middle badge rounded-pill bg-danger">
                    {unreadCount ? unreadCount : 0}
                  </span>
                )} */}
              </div>
            </div>
          ) : null}
          {userPermission("Missed Calls") ? (
            <div
              className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-2/5 flex relative flex-col justify-center"
              onClick={() => props.setIsMisscall(!props.isMisscall)}
            >
              <div className="flex justify-center flex-col w-full p-2">
                <small className="relative">Missed Calls</small>
                {missedCallsCount?.totalItems > 0 && (
                  <span className="left-[85px] absolute top-4 translate-middle badge rounded-pill bg-danger">
                    {missedCallsCount?.totalItems
                      ? missedCallsCount?.totalItems
                      : 0}
                  </span>
                )}
              </div>
            </div>
          ) : null}

          {isUpdating ? (
            <div
              className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-[82.2px] flex text-center leading-4 relative flex-col justify-center"
              onClick={() => props.setIsRecordings(!props.isRecordings)}
            >
              <div className="flex justify-center items-center flex-col">
                <BsFillRecordCircleFill size={13} />
                <small>Recordings</small>
              </div>
            </div>
          ) : null}
          {userPermission("Timeline") ? (
            <div
              className="after:content-[''] after:absolute after:h-10 after:w-1 after:bg-gradient-to-r after:from-black/[0.1] after:to-black/[0] text-[#5f5f5f] cursor-pointer h-full w-[82.2px] flex text-center leading-4 relative flex-col justify-center"
              onClick={() => props.setIsTimeline(!props.isTimeline)}
            >
              <div className="flex justify-center items-center flex-col">
                <FaArrowUp size={13} />
                <small>Timeline</small>
              </div>
            </div>
          ) : null}
        </div>
      </footer>
    </>
  );
};

export default Footer;
