import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  getApiUser,
  createApiUser,
  toggleActive,
  resendEmail,
  getApiUserDrd,
} from "../services/apiUserService";

const initialState = {
  isError: false,
  isLoading: false,
  record: [],
  apiUsersDrd: [],
  message: "",
};
const getErrorMessage = (error) => {
  return (
    error?.response?.data?.error ||
    (error?.response?.data?.debugInfo && [error?.response?.data?.debugInfo]) ||
    error?.response?.data?.message ||
    error?.response?.data ||
    error.toString()
  );
};
// Get API Users
export const get_api_users = createAsyncThunk(
  "get_api_users",
  async (thunkAPI) => {
    try {
      return await getApiUser();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const get_api_users_drd = createAsyncThunk(
  "get_api_users_drd",
  async (thunkAPI) => {
    try {
      return await getApiUserDrd();
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
// Create New API User
export const create_api_users = createAsyncThunk(
  "create_api_users",
  async (data, thunkAPI) => {
    try {
      return await createApiUser(data);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const handle_toggle_active = createAsyncThunk(
  "handle_toggle_active",
  async (id, thunkAPI) => {
    try {
      return await toggleActive(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resend_email = createAsyncThunk(
  "resend_email",
  async (id, thunkAPI) => {
    try {
      return await resendEmail(id);
    } catch (error) {
      const message = getErrorMessage(error);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const apiUserSlice = createSlice({
  name: "apiUsers",
  initialState,
  reducers: {
    apiUserReset: (state) => {
      state.isError = false;
      state.isLoading = false;
      state.record = [];
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(get_api_users.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_api_users.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record = action.payload?.data;
      })
      .addCase(get_api_users.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(create_api_users.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(create_api_users.fulfilled, (state, action) => {
        state.isLoading = false;
        state.record?.push(action.payload.data?.user);
      })
      .addCase(create_api_users.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(handle_toggle_active.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(handle_toggle_active.fulfilled, (state, action) => {
        state.isLoading = false;
        const result = state.record.findIndex(
          ({ _id }) => _id === action?.payload?.data?._id
        );
        state.record[result].active = action?.payload?.data?.active;
      })
      .addCase(handle_toggle_active.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(resend_email.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(resend_email.fulfilled, (state, action) => {
        state.isLoading = false;
        toast.success(
          action.payload.data.message ||
            action.payload.data ||
            "Token has been sent successfully"
        );
      })
      .addCase(resend_email.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        toast.error(action.payload ? action.payload : "Unknown Error");
      })
      .addCase(get_api_users_drd.pending, (state) => {
        state.isLoading = true;
        // state.record = [];
      })
      .addCase(get_api_users_drd.fulfilled, (state, action) => {
        state.isLoading = false;
        state.apiUsersDrd = action.payload?.data;
      })
      .addCase(get_api_users_drd.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      });
  },
});

export const { apiUserReset } = apiUserSlice.actions;
export default apiUserSlice.reducer;
